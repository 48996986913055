<template>
  <div class="task">
    <div v-show="!isShowTerminal && !isShowNodeDetail" v-loading="loading" class="task-content">
      <div class="view">
        <div class="view-title">总览</div>
        <div class="view-content">
          <div class="item">
            <div class="box">
              <div>
                <img src="@/assets/icon/submit.png" alt="">
              </div>
              <div>
                <div class="box-name">今日提交</div>
                <div class="unit"><span class="number">{{ todayJobUsage.jobSubmited }}</span>个</div>
              </div>
            </div>
            <div class="box">
              <div>
                <img src="@/assets/icon/finish.png" alt="">
              </div>
              <div>
                <div class="box-name">今日完成</div>
                <div class="unit"><span class="number">{{ todayJobUsage.jobEnded }}</span>个</div>
              </div>
            </div>
            <div class="box">
              <div>
                <img src="@/assets/icon/run.png" alt="">
              </div>
              <div>
                <div class="box-name">运行中作业</div>
                <div class="unit"><span class="number">{{ runningJobCount }}</span>个</div>
              </div>
            </div>
          </div>
        </div>
        <div class="view-content">
          <div class="left">
            <div v-if="isShowEcharts" id="spaceCountId" class="pie-echart" style="width:100%; height:160px" />
          </div>
          <div class="right">
            <div id="jobCount" class="pie-echart" style="width:100%; height:160px" />
          </div>
        </div>
      </div>
      <div class="header">
        <div>
          <el-button @click="stopJobsHandle"><svg-icon icon-class="stop" />停止</el-button>
          <el-button @click="logHandle"><svg-icon icon-class="log" />查看日志</el-button>
          <el-button @click="queryWindowHandle"><svg-icon icon-class="log" />查看窗口图传</el-button>
          <el-input
            v-model="search"
            class="search"
            placeholder="搜索"
            prefix-icon="Search"
            clearable
            @input="searchHandle(search)"
          />
        </div>
        <div class="terminal-show" @click="clickShowTerminal">
          <img src="@/assets/icon/terminal.png" alt="">
        </div>
      </div>
      <div class="tasks">
        <el-table
          ref="multipleTable"
          :data="jobList"
          tooltip-effect="dark"
          style="width: 100%; height: auto"
          :row-style="tableCellStyle"
          @row-click="rowClick"
          @row-contextmenu="rightClick"
        >
          <el-table-column prop="idJob" label="作业ID" show-overflow-tooltip>
            <template #default="scope">
              <span style="cursor: pointer; color: blue" @click="showNodeDetail(scope.row)">
                {{ scope.row.idJob }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="jobName" label="作业名称" show-overflow-tooltip />
          <el-table-column prop="cpusReq" label="作业规模" show-overflow-tooltip />
          <el-table-column prop="state" label="作业状态" show-overflow-tooltip>
            <template #default="scope">
              <div
                v-if="scope.row.state === 'JOB_RUNNING' || scope.row.state === 'JOB_PENDING'"
                class="state_circles"
                style="color: #437aec"
              >
                <div class="circles" style="background-color: #437aec" />
                <div>{{ scope.row.state }}</div>
              </div>
              <div
                v-if="scope.row.state === 'JOB_COMPLETE' || scope.row.state === 'JOB_END'"
                class="state_circles"
                style="color: #15a675"
              >
                <div class="circles" style="background-color: #15a675" />
                <div>{{ scope.row.state }}</div>
              </div>
              <div
                v-if="scope.row.state === 'JOB_PADDING' || scope.row.state === 'JOB_REQUEUED'"
                class="state_circles"
                style="color: #fd8e3f"
              >
                <div class="circles" style="background-color: #fd8e3f" />
                <div>{{ scope.row.state }}</div>
              </div>
              <div
                v-if="scope.row.state === 'JOB_CANCELLED'"
                class="state_circles"
                style="color: #909193"
              >
                <div class="circles" style="background-color: #909193" />
                <div>{{ scope.row.state }}</div>
              </div>
              <div
                v-if="scope.row.state === 'JOB_FAILED' || scope.row.state === 'JOB_TIMEOUT' || scope.row.state === 'JOB_NODE_FAIL' || scope.row.state === 'JOB_PREEMPTED' || scope.row.state === 'JOB_BOOT_FAIL' || scope.row.state === 'JOB_DEADLINE' || scope.row.state === 'JOB_OOM'"
                class="state_circles"
                style="color: #ff1e1e"
              >
                <div class="circles" style="background-color: #ff1e1e" />
                <div>{{ scope.row.state }}</div>
              </div>
              <div v-if="scope.row.state === 'JOB_SUSPENDED'" class="state_circles" style="color: #ED7B2F">
                <div class="circles" style="background-color: #ED7B2F" />
                <div>{{ scope.row.state }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="timeSubmit" :formatter="formatTime" label="创建时间" show-overflow-tooltip />
        </el-table>
        <div class="pagination">
          <div class="total">合计：{{ totalPage }}</div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            layout="sizes"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
          <div class="jumper">
            <div>{{ pageNum }}/{{ pagenumber }}</div>
            <div class="jumper-line" />
            <el-pagination
              v-model:currentPage="pageNum"
              :page-size="pageSize"
              :page-count="pagenumber"
              :page-sizes="pageSizes"
              background
              layout="jumper"
              :total="totalPage"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
          <el-pagination
            v-model:currentPage="pageNum"
            :page-size="pageSize"
            :page-count="pagenumber"
            :page-sizes="pageSizes"
            background
            layout="prev, next"
            :total="totalPage"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div class="creat-dialog">
        <el-dialog v-model="logVisible" title="日志" :close-on-click-modal="false" :before-close="beforeClose">
          <div id="content" ref="content" style="margin: 10px 0" />
          <div class="dialog-footer">
            <!--          <el-button size="small" @click="workNameVisible = false">取消</el-button>-->
            <el-button size="small" type="primary" @click="closeLogDia">确定</el-button>
          </div>
        </el-dialog>
      </div>
      <div v-show="menuVisible" id="contextmenu" class="taskMenu">
        <div class="contextmenu-item" @click="stopJobsRight(CurrentRow)">
          <svg-icon icon-class="stop-task" />
          停止
        </div>
        <div class="contextmenu-item" @click="logRight(CurrentRow)">
          <svg-icon icon-class="log-look" />
          查看日志
        </div>
        <div class="contextmenu-item" @click="queryWindowRight(CurrentRow)">
          <svg-icon icon-class="log-look" />
          查看窗口图传
        </div>
        <div
          v-show="CurrentRow.state === 'JOB_RUNNING'"
          class="contextmenu-item"
          @click="suspendJobRightHandle(CurrentRow, 'suspend')"
        >
          <svg-icon icon-class="start-svg" />
          挂起
        </div>
        <div
          v-show="CurrentRow.state === 'JOB_SUSPENDED'"
          class="contextmenu-item"
          @click="suspendJobRightHandle(CurrentRow, 'resume')"
        >
          <svg-icon icon-class="pause-svg" />
          恢复
        </div>
      </div>
    </div>
    <div
      v-show="isShowTerminal && !isShowNodeDetail"
      v-loading="terminalLoading"
      class="terminal-box"
      style="width:100%;height:100%"
    >
      <div class="min-size">
        <div class="svg-box" @click="miniHandle"><svg-icon icon-class="mini" /></div>
        <div class="svg-box" @click="closeHandle"><svg-icon icon-class="close" /></div>
      </div>
      <!-- <div id="xtermTask" class="xtermTask" style="width:100%;height:calc(100% - 20px)" @contextmenu.prevent="rightShow">
        <div v-if="waitLoading" class="wait-loading">
          <div>
            <img src="@/assets/icon/terminal-loading.png" alt="">
            <div class="wait-loading-text">等待连接...</div>
          </div>
        </div>
      </div> -->
      <TerminalBody
        ref="terminalBody"
        box-class="terminal-box"
        :height="terminalHeight"
        login-type="Job"
        :base64="terminalBase64"
        path="/sshSocket/ws/vnc/ssh/terminal"
        @changeStatus="changeStatus"
      />
    </div>
    <div v-show="isShowNodeDetail" v-loading="detailLoading" class="node-detail">
      <div class="back-btn" style=" cursor: pointer;" @click="backHandle">
        <el-icon>
          <ArrowLeft />
        </el-icon>
        作业管理
      </div>
      <div class="detail-body">
        <div class="detail-card detail-top" style="margin-bottom: 30px;">
          <div class="title">基本信息</div>
          <div class="content">
            <div class="content-item">
              <span>作业ID:</span>
              <span :title="jobInfo.jobID">{{ jobInfo.jobID || '-' }}</span>
            </div>
            <div class="content-item">
              <span>作业名称:</span>
              <span :title="jobInfo.jobName">{{ jobInfo.jobName || '-' }}</span>
            </div>
            <div class="content-item" style="display: flex;">
              <span>作业状态:</span>
              <div :class="['job-state', getStatusBackSty(jobInfo.state)]">
                <div :class="getStatusIconSty(jobInfo.state)">
                  <!-- <div class="big-circle" /> -->
                  <div class="small-circle" />
                </div>
                <div :class="getStatusTextSty(jobInfo.state)">{{ jobInfo.state || '-' }}</div>
              </div>
            </div>
            <div class="content-item">
              <span>作业执行总时长:</span>
              <span :title="jobInfo.runningTime">{{ jobInfo.runningTime || '-' }}</span>
            </div>
            <div class="content-item">
              <span>算例目录:</span>
              <span :title="jobInfo.workDir">{{ jobInfo.workDir || '-' }}</span>
            </div>
            <div class="content-item">
              <span>结果输出目录:</span>
              <span :title="jobInfo.workDir">{{ jobInfo.workDir || '-' }}</span>
            </div>
            <div class="content-item">
              <span>标准输出日志目录:</span>
              <span :title="jobInfo.criterionDir">{{ jobInfo.criterionDir ||"-" }}</span>
            </div>
            <div class="content-item">
              <span>错误输出日志目录:</span>
              <span :title="jobInfo.errorDir">{{ jobInfo.errorDir || '-' }}</span>
            </div>
            <div class="content-item">
              <span>
                运行节点:</span>
              <span :title="jobInfo.nodelist">{{ jobInfo.nodelist || '-' }}</span>
            </div>
          </div>
        </div>
        <div class="detail-card detail-center" style="margin-bottom: 30px;">
          <div class="title">作业详细信息</div>
          <div class="content" style="grid-template-rows: 1fr 1fr;">
            <div class="content-item">
              <span>最大内存使用量:</span>
              <span :title="jobdata[0].maxRss">{{ jobdata[0].maxRss || '-' }}</span>
            </div>
            <div class="content-item">
              <span>平均内存使用量:</span>
              <span :title="jobdata[0].aveRss">{{ jobdata[0].aveRss || '-' }}</span>
            </div>
            <div class="content-item">
              <span>最大读取速率:</span>
              <span :title="jobdata[0].maxDiskRead">{{ jobdata[0].maxDiskRead || '-' }}</span>
            </div>
            <div class="content-item">
              <span>最大写入速率:</span>
              <span :title="jobdata[0].maxDiskWrite">{{ jobdata[0].maxDiskWrite || '-' }}</span>
            </div>
            <div class="content-item">
              <span>平均读取速率:</span>
              <span :title="jobdata[0].aveDiskRead">{{ jobdata[0].aveDiskRead || '-' }}</span>
            </div>
            <div class="content-item">
              <span>平均写入速率:</span>
              <span :title="jobdata[0].aveDiskWrite">{{ jobdata[0].aveDiskWrite || '-' }}</span>
            </div>
          </div>
        </div>
        <div v-if="isShowDetailEcharts" class="detail-bottom">
          <div class="detail-card">
            <div class="title">
              CPU利用率
            </div>
            <!-- <div class="line-cpu-echarts" /> -->
            <task-echarts-line1 :is-show-node-detail="isShowNodeDetail" :data="cpuData" :x-data="xData" />
          </div>
          <div class="detail-card">
            <div class="title">
              内存利用率
            </div>
            <task-echarts-line2 :is-show-node-detail="isShowNodeDetail" :data="memoryData" :x-data="xData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getJobNodeUsage, jobList, stopJobs, queryVncStatus, getJobsStatus, getQuota, getTodayJobUsage, getRunningJobCount, getJobDetail, suspendJob } from '@/api/task'
import 'xterm/css/xterm.css'
import { Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import { AttachAddon } from 'xterm-addon-attach'
import 'xterm/css/xterm.css'
import 'xterm/lib/xterm.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { mapGetters } from 'vuex'
import { markRaw } from 'vue'
import TaskNodeLineEcharts from '../components/TaskNodeLineEcharts.vue'
import TerminalBody from '@/components/TerminalBody.vue'
import TaskEchartsLine1 from '../components/TaskEchartsLine1.vue'
import TaskEchartsLine2 from '../components/TaskEchartsLine2.vue'
let taskChartPies = null
let taskChartSpacePies = null
const Base64 = require('js-base64').Base64
export default {
  name: 'Task',
  data() {
    return {
      isShowEcharts: true,
      isMini: false,
      rowXterm: 24,
      col: 80,
      logs: 'Welcome to use Superman. ',
      runningJobCount: 0,
      isShowTerminal: false,
      init: true,
      logVisible: false,
      vncSwitch: false,
      term: null,
      socket: null,
      fitAddon: '',
      ws: {},
      baseUrl: 'wss://192.168.132.234:9090/slurm/log?path=',
      pageSize: 10,
      row: '',
      pageNum: 1,
      pagenumber: 0,
      totalPage: 0,
      pageSizes: [5, 10, 20, 30],
      search: '',
      menuVisible: false,
      loading: true,
      terminalLoading: false,
      timer: '',
      textArea: null,
      todayJobUsage: {},
      jobList: [
        {
          id: 11,
          name: 'ROMS',
          scale: '3核',
          status: '运行中',
          createTime: '2021-12-11 16:30'
        },
        {
          id: 12,
          name: 'ROMS',
          scale: '3核',
          status: '已完成',
          createTime: '2021-12-11 16:30'
        },
        {
          id: 13,
          name: 'ROMS',
          scale: '3核',
          status: '排队中',
          createTime: '2021-12-11 16:30'
        },
        {
          id: 14,
          name: 'ROMS',
          scale: '3核',
          status: '异常',
          createTime: '2021-12-11 16:30'
        }
      ],
      myChart: null,
      pieData: [],
      jobCount: 0,
      spaceCount: 0,
      spaceData: [],
      password: '',
      spaceObj: {
        'usage': 220,
        'limit': 209715200
      },
      isHavePsd: false,
      searchTimer: null,
      isShowNodeDetail: false,
      nodeUsageData: [],
      nodeDetailTimer: null,
      waitLoading: false,
      terminalBase64: '',
      terminalHeight: '95%',
      jobdata: [
        {
          'jobId': '',
          'aveDiskRead': '',
          'aveDiskWrite': '',
          'maxRss': '',
          'aveRss': '',
          'maxDiskRead': '',
          'maxDiskWrite': '',
          'elapsed': ''
        }
      ],
      jobInfo: {},
      isShowDetailEcharts: false,
      cpuData: [],
      memoryData: [],
      xData: [],
      CurrentRow: {},
      detailLoading: false

    }
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  },

  watch: {
    isShowNodeDetail(val) {
      if (val) {
        return
      } else {
        clearInterval(this.nodeDetailTimer)
        this.$nextTick(() => {
          taskChartPies.resize()
          taskChartSpacePies.resize()
        })
      }
    }
  },
  components: {
    TaskNodeLineEcharts,
    TerminalBody,
    TaskEchartsLine1,
    TaskEchartsLine2
  },
  mounted() {
    this.getView()
    this.getQuotaData()
    const that = this
    this.timer = setInterval(() => {
      that.refresh()
    }, 5000)
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    // 创建实例带参
    const erdUltraFast = elementResizeDetectorMaker({
      strategy: 'scroll', // <- For ultra performance.
      callOnAdd: true,
      debug: true
    })
    const echarts = require('echarts')
    taskChartPies = echarts.init(document.getElementById('jobCount'))
    taskChartSpacePies = echarts.init(document.getElementById('spaceCountId'))
    // 监听id为test的元素 大小变化
    const i = document.getElementsByClassName('task').length - 1
    erd.listenTo(document.getElementsByClassName('task')[i], function(element) {
      try {
        setTimeout(() => {
          taskChartPies.resize()
          taskChartSpacePies.resize()
          if (that.isShowTerminal) {
            if (that.fitAddon !== '') {
              that.fitAddon.fit()
            }
          }
        }, 100)
      } catch (error) {
        console.log(error)
      }
    })
  },
  beforeUnmount() {
    if (this.nodeDetailTimer) {
      clearInterval(this.nodeDetailTimer)
    }
    this.isShowEcharts = false
    clearInterval(this.timer)
    this.timer = ''
    this.myChart.dispose()
    try {
      this.socket.close()
      this.term.dispose()
    } catch (error) {
      console.log(error)
    }
  },
  methods: {
    // 获取磁盘配额
    getQuotaData() {
      var unUsage = 0
      getQuota().then(res => {
        if (res.meta.status === 200) {
          this.spaceObj = res.data
          if (this.spaceObj.limit === 0) {
            unUsage = this.spaceObj.usage
            const usage = 2207230700
            this.spaceCount = '无限制'
            this.spaceData.push({
              name: '已使用',
              value: parseFloat((this.spaceObj.usage / 1048576).toFixed(1))
            }, {
              name: '未使用',
              value: parseFloat((this.spaceObj.usage / 1048576).toFixed(1))
            })
            this.$nextTick(() => {
              this.initSpaceEcharts()
            })
          } else {
            unUsage = this.spaceObj.limit - this.spaceObj.usage
            this.spaceCount = (this.spaceObj.limit / 1048576).toFixed(0)
            this.spaceData.push({
              name: '已使用',
              value: parseFloat((this.spaceObj.usage / 1048576).toFixed(1))
            }, {
              name: '未使用',
              value: parseFloat((unUsage / 1048576).toFixed(1))
            })
            this.$nextTick(() => {
              this.initSpaceEcharts()
            })
          }
        }
      })
    },
    refresh() {
      this.jobCount = 0
      getJobsStatus().then(res => {
        if (res.meta.status === 200) {
          const data = res.data
          this.jobsStatusData = data
          const pieData = data
          pieData.forEach(item => {
            this.jobCount += item.value
            if (item.name === 'JOB_RUNNING') {
              item.name = '运行中'
            } else if (item.name === 'JOB_PENDING') {
              item.name = '排队中'
            } else if (item.name === 'JOB_COMPLETE') {
              item.name = '已完成'
            } else {
              item.name = '异常'
            }
          })
          this.pieData = pieData
          console.log(pieData)
          this.getTodayJobUsage()
          this.getRunningJobCount()
          jobList(this.pageNum, this.pageSize, this.search)
            .then((response) => {
              this.jobList = response.data.content
              this.pagenumber = response.data.totalpages
              this.totalPage = response.data.totalelements
            })
          this.$nextTick(() => {
            this.initEcharts()
          })
        }
      })
    },
    // 获取今日近况
    getTodayJobUsage() {
      getTodayJobUsage().then(res => {
        if (res.meta.status === 200) {
          this.todayJobUsage = res.data
        } else {
          ElMessage.error(res.meta.msg)
        }
      })
    },
    // 获取运行中作业数量
    getRunningJobCount() {
      getRunningJobCount().then(res => {
        if (res.meta.status === 200) {
          this.runningJobCount = res.data
        } else {
          ElMessage.error(res.meta.msg)
        }
      })
    },
    getView() {
      this.loading = true
      getJobsStatus().then(res => {
        if (res.meta.status === 200) {
          const data = res.data
          this.jobsStatusData = data
          const pieData = data
          pieData.forEach(item => {
            this.jobCount += item.value
            if (item.name === 'JOB_RUNNING') {
              item.name = '运行中'
            } else if (item.name === 'JOB_PENDING') {
              item.name = '排队中'
            } else if (item.name === 'JOB_COMPLETE') {
              item.name = '已完成'
            } else {
              item.name = '异常'
            }
          })
          this.pieData = pieData
          this.getTodayJobUsage()
          this.getRunningJobCount()
          const that = this
          setTimeout(
            function() {
              that.initSpaceEcharts()
              that.initEcharts()
              that.getData()
            }, 1000)
        } else {
          ElMessage.error(res.meta.msg)
        }
      })
    },
    // 用户空间 饼图
    initSpaceEcharts() {
      const echarts = require('echarts')
      this.myChart = markRaw(echarts.init(document.getElementById('spaceCountId')))
      const option = {
        color: ['#FD8E3F', '#249EFF'],
        title: {
          text: this.spaceCount,
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#313a46',
            fontSize: 18
          },
          subtext: '全部(GB)',
          subtextStyle: {
            color: '#939ea9',
            fontSize: 12,
            align: 'right'
          },
          triggerEvent: true
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{c}GB({d}%)'

        },
        legend: {
          orient: '', // 删除此项图例是水平展示，添加此项图例垂直展示
          x: 'right', // x : 左（left）、右（right）、居中（center）
          y: 'center', // y : 上（top）、下（bottom）、居中（center）
          itemHeight: 8,
          padding: [0, 0, 0, 0], // padding:[0,30,0,0] [（上），（右）、（下）、（左）]
          icon: 'circle',
          textStyle: {
            // 图例文字的样式
            color: '#4E5969',
            fontSize: 12
          },
          data: ['未使用', '已使用']
        },
        series: [
          {
            name: '磁盘配额',
            type: 'pie',
            radius: ['69%', '93%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              normal: {
                show: false,
                position: 'outside', // 另有参数inside，可以让数据在圆环上
                formatter: '{d}%', // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                textStyle: {
                  // 牵引线上的文字的样式
                  align: 'right',
                  fontSize: 12,
                  color: '#333'
                }
              }
            },
            labelLine: {
              show: false // 是否显示延展线
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: this.spaceData
          }
        ]
      }
      this.myChart.setOption(option)
    },
    // 作业统计 饼图
    initEcharts() {
      const echarts = require('echarts')
      this.myChart = markRaw(echarts.init(document.getElementById('jobCount')))
      const option = {
        color: ['#313CA9', '#249EFF', '#86DF6C', '#FD8E3F'],
        title: {
          text: this.jobCount,
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#313a46',
            fontSize: 18
          },
          subtext: '全部(个)',
          subtextStyle: {
            color: '#939ea9',
            fontSize: 12,
            align: 'right'
          },
          triggerEvent: true
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}:{c}({d}%)'
        },
        legend: {
          orient: '', // 删除此项图例是水平展示，添加此项图例垂直展示
          x: 'right', // x : 左（left）、右（right）、居中（center）
          y: 'center', // y : 上（top）、下（bottom）、居中（center）
          itemHeight: 8,
          padding: [0, 0, 0, 0], // padding:[0,30,0,0] [（上），（右）、（下）、（左）]
          icon: 'circle',
          textStyle: {
            // 图例文字的样式
            color: '#4E5969',
            fontSize: 12
          },
          data: ['运行中', '排队中', '已完成', '异常']
        },
        series: [
          {
            name: '作业统计',
            type: 'pie',
            radius: ['69%', '93%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 0
            },
            label: {
              normal: {
                show: false,
                position: 'outside', // 另有参数inside，可以让数据在圆环上
                formatter: '{d}%', // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
                textStyle: {
                  // 牵引线上的文字的样式
                  align: 'right',
                  fontSize: 12,
                  color: '#333'
                }
              }
            },
            labelLine: {
              show: false // 是否显示延展线
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            data: this.pieData
          }
        ]
      }
      this.myChart.setOption(option)
    },
    // 搜索
    searchHandle() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.pageNum = 1
        this.$nextTick(() => {
          this.getData()
        })
      }, 500)
    },
    rowClick(row) {
      this.row = row
    },
    showNodeDetail(row) {
      this.row = row
      // if (row.state === 'JOB_RUNNING') {
      //   this.getJobNodeUsage()
      // } else {
      //   ElMessage.warning('请选择JOB_RUNNING状态节点')
      // }
      this.isShowNodeDetail = true
      this.detailLoading = true
      document.getElementsByClassName('task')[0].style.backgroundColor = '#f5f5f5'
      getJobDetail(row.idJob).then(res => {
        this.jobdata = res.data.jobdata.length > 0 ? res.data.jobdata : this.jobdata
        this.jobInfo = res.data.jobInfo
        this.jobInfo.criterionDir = this.jobInfo.workDir + '/slurm-' + this.jobInfo.jobID + '.out'
        this.jobInfo.errorDir = this.jobInfo.workDir + '/slurm-' + this.jobInfo.jobID + '.out'
        this.jobInfo.runningTime = this.formatSeconds(this.jobInfo.runningTime)
        this.detailLoading = false
      })
      if (row.state === 'JOB_RUNNING') {
        this.getJobNodeUsage(row.idJob)
        this.isShowDetailEcharts = true
      }
    },
    formatSeconds(seconds) {
      // 初始化小时、分钟和秒
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor((seconds % 3600) / 60)
      const secs = seconds % 60
      // 如果小时为0，并且分钟也为0，则只返回秒数
      if (hours === 0 && minutes === 0) {
        return secs + '秒'
      }
      // 如果小时为0，则只返回分钟和秒数
      if (hours === 0) {
        return minutes + '分' + (secs < 10 ? '0' : '') + secs + '秒'
      }
      // 如果小时不为0，则返回小时、分钟和秒数
      return hours + '小时' + minutes + '分' + (secs < 10 ? '0' : '') + secs + '秒'
    },
    // 节点详情
    getJobNodeUsage() {
      getJobNodeUsage(30, this.row.idJob).then(res => {
        if (res.meta.status === 200) {
          this.isShowNodeDetail = true
          document.getElementsByClassName('task')[0].style.backgroundColor = '#f5f5f5'
          this.getNodesData(res.data)
        } else {
          ElMessage.error('该节点状态异常')
        }
      }).catch(() => {
        if (this.isShowNodeDetail) {
          this.backHandle()
        }
      })
    },
    // 处理数据
    getNodesData(data) {
      const obj = {}
      const cpuArr = []
      const memoryArr = []
      const cpu = data.cpus
      const memory = data.memorys
      cpu.forEach(item => {
        obj.nodeName = item.nodename
        if (item.values.length <= 6) {
          obj.data = item.values.map(i => {
            return Number(i[1]).toFixed(2)
          })
        } else {
          const gap = Math.floor(item.values.length / 6)
          let index = 0
          obj.data = []
          while (index < item.values.length) {
            obj.data.push(Number(item.values[index][1]).toFixed(2))
            index += gap
          }
        }

        cpuArr.push(JSON.parse(JSON.stringify(obj)))
      })

      memory.forEach((item) => {
        const obj = {}
        obj.nodeName = item.nodename
        if (item.values.length <= 6) {
          obj.data = item.values.map(i => {
            return Number(i[1]).toFixed(2)
          })
        } else {
          const gap = Math.floor(item.values.length / 6)
          let index = 0
          obj.data = []
          while (index < item.values.length) {
            obj.data.push(Number(item.values[index][1]).toFixed(2))
            index += gap
          }
        }
        memoryArr.push(JSON.parse(JSON.stringify(obj)))
      })
      if (cpu.length > 0) {
        this.xData = []
        const c = cpu[0].values
        if (c.length <= 6) {
          this.xData = c.map(i => {
            return this.timestampToTime(i[0])
          })
        } else {
          const gap = Math.floor(c.length / 6)
          let index = 0
          while (index < c.length) {
            this.xData.push(this.timestampToTime(c[index][0]))
            index += gap
          }
        }
      }

      this.cpuData = cpuArr
      this.memoryData = memoryArr
    },
    timestampToTime(timestamp) {
      timestamp = timestamp || null
      const date = new Date(timestamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = date.getFullYear() + '-'
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
      const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      return h + m
    },
    backHandle() {
      this.isShowNodeDetail = false
      this.isShowDetailEcharts = false
      this.nodeUsageData = null
      document.getElementsByClassName('task')[0].style.backgroundColor = '#fff'
    },
    tableCellStyle(row) {
      if (this.row === row.row) {
        return 'background-color:#f5f6fa;'
      } else {
        return 'background-color:#fff;'
      }
    },
    // 时间格式
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property] * 1000
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.pageNum = 1
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
    // xterm右键
    rightShow() {
      if (this.socket !== null) {
        if (window.isSecureContext) {
          navigator.clipboard.readText()
            .then(text => {
              this.socket.send(text)
            })
        } else {
          this.socket.send(this.textArea.textContent)
        }
      }
    },
    // 右键
    rightClick(row, column, event) {
      this.testModeCode = row.testModeCode
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() // 关闭浏览器右键默认事件
      this.CurrentRow = row
      var menu = document.querySelector('.taskMenu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      window.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu(menu, event) {
      var y = event.clientY
      var x = event.clientX
      menu.style.left = x + 'px'
      window.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    getData() {
      this.loading = true
      jobList(this.pageNum, this.pageSize, this.search)
        .then((response) => {
          this.jobList = response.data.content
          this.pagenumber = response.data.totalpages
          this.totalPage = response.data.totalelements
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 查看窗口图传 右键
    queryWindowRight(row) {
      if (row.state !== 'JOB_RUNNING') {
        ElMessage.warning('该作业未运行中，不可查看窗口图传！')
        return
      }
      queryVncStatus(row.idJob).then((response) => {
        if (response.data === true) {
          localStorage.idJob = row.idJob
          this.$nextTick(() => {
            this.$store.dispatch('core/openApp', 6372403323111393)
          })
        } else {
          ElMessage.error(response.meta.msg)
        }
      })
    },
    queryWindowHandle() {
      if (this.row === '') {
        ElMessage.warning('请单击选中作业或者右键进行该操作')
        return
      }
      if (this.row.state !== 'JOB_RUNNING') {
        ElMessage.warning('该作业未运行中，不可查看窗口图传！')
        return
      }
      queryVncStatus(this.row.idJob).then((response) => {
        if (response.data === true) {
          localStorage.idJob = this.row.idJob
          this.$nextTick(() => {
            this.$store.dispatch('core/openApp', 6372403323111393)
          })
        } else {
          ElMessage.error(response.meta.msg)
        }
        this.row = ''
      })
    },
    stopJobsHandle() {
      if (this.row === '') {
        ElMessage.warning('请单击选中作业或者右键进行该操作')
        return
      }
      stopJobs(this.row.idJob).then((response) => {
        if (response.meta.status === 200) {
          ElMessage.success('停止成功')
        } else {
          ElMessage.error(response.meta.msg)
        }
        this.row = ''
      })
    },
    // 停止
    stopJobsRight(row) {
      stopJobs(row.idJob).then((response) => {
        if (response.meta.status === 200) {
          ElMessage.success('停止成功')
        } else {
          ElMessage.error(response.meta.msg)
        }
      })
    },
    logHandle() {
      if (this.row === '') {
        ElMessage.warning('请单击选中作业或者右键进行该操作')
        return
      }
      localStorage.workDir = this.row.workDir
      localStorage.idJob = this.row.idJob
      // this.logVisible = true;
      // // 调查询日志接口
      this.$nextTick(() => {
        this.$store.dispatch('core/openApp', 6372403323111392)
      })
      // this.logs = "Welcome to use Superman. " + new Date();
      // this.getLogs();
    },
    // 查看日志
    logRight(row) {
      this.row = row
      localStorage.workDir = this.row.workDir
      localStorage.idJob = this.row.idJob
      this.$nextTick(() => {
        this.$store.dispatch('core/openApp', 6372403323111392)
      })
    },
    initXterm() {
      const cols = document.getElementById('content').offsetWidth / 9
      this.term = new Terminal({
        // 渲染类型
        rendererType: 'canvas',
        rows: 28,
        cols: parseInt(cols),
        convertEol: true,
        cursorStyle: 'bar',
        // 终端中的回滚量
        scrollback: 1000,
        disableStdin: true,
        theme: {
          cursor: 'help'
        }
      })
      this.term.open(this.$refs.content)
      const fitAddon = new FitAddon()
      this.term.loadAddon(fitAddon)
      fitAddon.fit()
      window.addEventListener('resize', resizeScreen)
      function resizeScreen() {
        fitAddon.fit()
      }
    },
    getLogs() {
      const url =
        this.baseUrl + this.row.workDir + '/slurm-' + this.row.idJob + '.out'
      this.ws = new WebSocket(url)
      this.ws.onopen = () => {
        ElMessage({
          message: '日志查询成功',
          type: 'success'
        })
      }
      this.ws.onmessage = (res) => {
        this.term.write(res.data)
      }
      this.ws.onerror = () => {
        ElMessage({
          message: '日志查询出错',
          type: 'error'
        })
      }
    },
    closeLogDia() {
      this.clear()
      this.logVisible = false
    },
    clear() {
      this.ws.close()
      this.ws = {}
      this.term.reset()
      this.row = ''
    },
    beforeClose(done) {
      this.clear()
      done()
    },
    // 最小化
    miniHandle() {
      this.isMini = true
      this.isShowTerminal = false
      document.querySelector('.task').style.backgroundColor = '#fff'
      this.$nextTick(() => {
        taskChartPies.resize()
        taskChartSpacePies.resize()
      })
    },
    closeHandle() {
      this.isMini = false
      this.isShowTerminal = false
      const e = document.querySelector('.task')
      e.style.backgroundColor = '#fff'
      this.$nextTick(() => {
        taskChartPies.resize()
        taskChartSpacePies.resize()
      })
      this.isHavePsd = false
      this.vncSwitch = false
      this.$refs.terminalBody.dispose()
    },
    // terminal查看
    clickShowTerminal() {
      if (this.isMini) {
        this.isShowTerminal = true
        document.querySelector('.task').style.backgroundColor = '#000'
        return
      }
      this.isShowTerminal = true
      const params = {
        token: sessionStorage.desktopToken,
        regionName: window.localStorage.regionName
      }
      document.querySelector('.task').style.backgroundColor = '#000'
      this.terminalBase64 = Base64.encode(JSON.stringify(params))
      this.$nextTick(() => {
        this.$refs.terminalBody.init()
      })
    },
    // 监听元素大小改变时 自适应
    listenerEventChange() {
      const that = this
      const elementResizeDetectorMaker = require('element-resize-detector')// 导入
      // 创建实例
      const erd = elementResizeDetectorMaker()
      // 创建实例带参
      const erdUltraFast = elementResizeDetectorMaker({
        strategy: 'scroll', // <- For ultra performance.
        callOnAdd: true,
        debug: true
      })
    },
    async connectHandle() {
      this.$nextTick(async() => {
        const width = document.getElementById('xtermTask').clientWidth
        const height = document.getElementById('xtermTask').clientHeight
        this.rowXterm = Math.floor(height / 14)
        this.col = Math.floor(width / 8.5)
        if (this.vncSwitch) {
          this.socketClose()
          this.term.dispose()
        }
        const Base64 = require('js-base64').Base64
        const data = {
          token: sessionStorage.desktopToken,
          col: this.col,
          row: this.rowXterm,
          regionName: window.localStorage.regionName
        }
        this.connect = Base64.encode(JSON.stringify(data))
        // const connect = 'eyJ0b2tlbiI6ImV5SjBlWEFpT2lKS1YxUWlMQ0poYkdjaU9pSklVekkxTmlKOS5leUoxYVdRaU9pSXpNRE0zTnpReE9UUXhNakV4T1RFeElpd2liR1JoY0ZWcFpDSTZJakV3TURRaUxDSnNaR0Z3UjJsa0lqb2lNVEF3TkNJc0ltVjRjQ0k2TVRjd01EWXlPVFF5TlN3aVlXTmpiM1Z1ZENJNkluTjFjR1Z5SW4wLmVTam90ZWQ2NnpMU082eHpnRjVxN0VpTXBYZ0VvbmNsSWNSNUtjZmpOTUUiLCJjb2wiOjE3OCwicm93Ijo4OX0='
        var url
        var ishttps = document.location.protocol == 'https:'
        if (ishttps) {
          url = 'wss:' + location.host + '/sshSocket/ws/vnc/sshHpcConnect?connect=' + this.connect + '&token=' + sessionStorage.desktopToken
        } else {
          url = 'ws:' + location.host + '/sshSocket/ws/vnc/sshHpcConnect?connect=' + this.connect + '&token=' + sessionStorage.desktopToken
        }
        this.default = url
        await this.initSocket()
      })
    },
    initTerm() {
      const term = new Terminal({
        scrollback: 500,
        fontSize: 14,
        row: this.rowXterm,
        cursorBlink: true,
        rendererType: 'canvas',
        // 启用时，光标将设置为下一行的开头
        convertEol: false,
        cols: this.col,
        theme: {
          cursor: 'help' // 设置光标
        }
      })
      const attachAddon = new AttachAddon(this.socket)
      const fitAddon = new FitAddon()
      term.loadAddon(attachAddon)
      term.loadAddon(fitAddon)
      term.open(document.getElementById('xtermTask'))
      fitAddon.fit()
      this.fitAddon = fitAddon
      term.focus()
      term.attachCustomKeyEventHandler(e => {
        // console.log('listen press key ' + e.keyCode)
        if (e.ctrlKey && e.keyCode === 76) {
          term.clear()
          fitAddon.fit()
        }
      })
      // 鼠标选中 是复制
      term.onSelectionChange(e => {
        if (term.hasSelection()) {
          if (window.isSecureContext) {
            navigator.clipboard.writeText(term.getSelection())
          } else {
            this.textArea = document.createElement('textarea')
            this.textArea.style.position = 'absolute'
            this.textArea.style.opacity = 0
            this.textArea.style.left = '-999999px'
            this.textArea.style.top = '-999999px'
            document.body.appendChild(this.textArea)
            this.textArea.textContent = term.getSelection()
            // 选择
            this.textArea.focus()
            this.textArea.select()
            // 复制
            document.execCommand && document.execCommand('copy')
          }
        }
      })
      term.onData(cmd => {
      })
      // 限制和后端交互，只有输入回车键才显示结果
      term.prompt = () => {
        term.write('\r\n$ ')
      }

      term.prompt()
      this.term = term
    },
    initSocket() {
      this.socket = new WebSocket(this.default)
      this.$nextTick(() => {
        this.terminalLoading = false
        this.waitLoading = false
      })
      this.socketOnClose()
      this.socketOnOpen()
      this.socketOnError()
    },
    socketOnOpen() {
      this.socket.onopen = () => {
        // 链接成功后
        this.vncSwitch = true
        this.initTerm()
      }
    },
    socketClose() {
      this.vncSwitch = false
      this.socket.close()
    },
    socketOnClose() {
      this.socket.onclose = () => {
        console.log('close socket')
      }
    },
    socketOnError() {
      this.vncSwitch = false
      this.socket.onerror = () => {
        console.log('socket 连接失败')
      }
    },
    suspendJobRightHandle(row, action) {
      let title = ''
      const actionText = action === 'suspend' ? '挂起' : '恢复'
      action === 'suspend' ? title = ('确定要将' + row.jobName + '作业挂起吗？') : (title = '确定要将' + row.jobName + '作业恢复吗？')
      ElMessageBox.confirm(title, action === 'suspend' ? '挂起作业' : '恢复作业', {
        confirmButtonText: '确认',
        cancelButtonText: '取消'
      }).then(() => {
        suspendJob(row.idJob, action).then((response) => {
          if (response.meta.status === 204) {
            this.getData()
            ElMessage.success(actionText + '成功')
          } else {
            ElMessage.error(response.data.message)
          }
        })
      }).catch(() => {
        ElMessage({
          type: 'info',
          message: '已取消'
        })
      })
    },
    getStatusIconSty(state) {
      switch (state) {
        case 'JOB_RUNNING':
          return 'normal circle'
        case 'JOB_PENDING':
          return 'normal circle'
        case 'JOB_SUSPENDED':
          return 'supended circle'
        case 'JOB_COMPLETE':
          return 'end circle'
        case 'JOB_CANCELLED':
          return 'cancelled circle'
        case 'JOB_FAILED':
          return 'anomaly circle'

        default:
          return 'anomaly circle'
      }
    },
    getStatusTextSty(state) {
      switch (state) {
        case 'JOB_RUNNING':
          return 'normal-text'
        case 'JOB_PENDING':
          return 'normal-text'
        case 'JOB_SUSPENDED':
          return 'suspended-text'
        case 'JOB_COMPLETE':
          return 'end-text'
        case 'JOB_CANCELLED':
          return 'cancelled-text'
        case 'JOB_FAILED':
          return 'anomaly-text'
        default:
          return 'anomaly-text'
      }
    },
    getStatusBackSty(state) {
      switch (state) {
        case 'JOB_RUNNING':
          return 'normal-bg'
        case 'JOB_PENDING':
          return 'normal-bg'
        case 'JOB_SUSPENDED':
          return 'suspended-bg'
        case 'JOB_COMPLETE':
          return 'end-bg'
        case 'JOB_CANCELLED':
          return 'cancelled-bg'
        case 'JOB_FAILED':
          return 'anomaly-bg'
        default:
          return 'anomaly-bg'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.task {
  overflow-y: auto;
  height: 100%;

  // background-color: #F5F5F5;
  // width: 100%;
  .job-state {
    display: flex;
    width: 158px;
    height: 32px;
    border-radius: 3px 3px 3px 3px;
    align-items: center;
    justify-content: start;
    margin-left: 8px;
    transform: translateY(-8px);
    padding-left: 8px;
    .small-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 8px;
    }

    .big-circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;

    }

    .normal {
      .big-circle {
        background-color: #c4dcfe;
      }

      .small-circle {
        background-color: #1677ff;
      }
    }
    .supended{
      .big-circle {
        background-color: rgba(237, 123, 47, 0.2);
      }

      .small-circle {
        background-color: rgba(237, 123, 47, 0.3);
      }
    }
    .cancelled {
      .big-circle {
        background-color: #f7f7f7;
      }

      .small-circle {
        background-color: #999;
      }
    }
    .anomaly {
      .big-circle {
        background-color: #ffecf0;
      }

      .small-circle {
        background-color: #fa5c7c;
      }
    }

    .warning {
      .big-circle {
        background-color: #feebde;
      }

      .small-circle {
        background-color: #fd8e3f;
      }
    }

    .end {
      .big-circle {
        background-color: #d6fbee;
      }

      .small-circle {
        background-color: #089e74;
      }
    }
  }

  .terminal-box {
    .min-size {
      width: 100%;
      height: 20px;
      line-height: 20px;
      background-color: #f5f5f5;
      display: flex;
      justify-content: flex-end;

      .svg-box {
        padding: 0 15px;
      }

      .svg-box:hover {
        background-color: #BBBBBB;
      }
    }
  }

  .task-content {
    margin: 20px;

    .view {
      padding-bottom: 23px;

      .view-title {
        padding-bottom: 13px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }

      .view-content {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        .item {
          display: flex;
          width: 100%;
          height: 120px;

          .box {
            display: flex;
            align-items: center;
            padding: 0 20px;
            // min-width: 160px;
            width: 33%;
            box-sizing: border-box;

            img {
              margin-right: 10px;
            }

            .box-name {
              padding-bottom: 6px;
              color: #1D2129;
              font-size: 14px;
            }

            .unit {
              color: #4E5969;
              font-size: 12px;
            }

            .number {
              font-size: 24px;
              font-weight: bold;
              padding-right: 8px;
              color: #1D2129;
            }
          }
        }

        .left {
          width: 50%;
          // border-left: 1px solid #f2f3f5;
          margin-right: 3%;

          .pie-echart {
            height: 160px;
            width: 100%;
          }
        }

        .right {
          width: 50%;
          border-left: 1px solid #f2f3f5;

          .pie-echart {
            height: 160px;
            width: 100%;
          }
        }
      }
    }
  }

  .taskMenu {
    position: fixed;
    background-color: #fff;
    width: 160px;
    padding: 0 10px;
    // height: 129px;
    font-size: 12px;
    color: #313a46;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #E8EEF3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 10000;

    .svg-icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 11px;
    }
  }

  ::v-deep .el-select-dropdown__item.selected {
    font-weight: 500 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: flex-end;

    ::v-deep .el-button--primary {
      background: #437aec;
      border-color: #437aec;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ::v-deep .el-icon {
      color: #a8abb2;
      font-size: 16px;
    }

    .terminal-show {
      img {
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
    }

    ::v-deep .el-input {
      --el-input-focus-border: #dcdfe6;
    }

    .search {
      width: 30%;

      ::v-deep .el-input__inner {
        border-radius: 2px;
        height: 34px;
        line-height: 34px;
        // width: 20%;
      }
    }

    ::v-deep .el-button {
      margin-right: 10px;
      background-color: #437aec;
      color: #fff;
      padding: 10px 16px;
      border-radius: 2px;
      min-height: 34px;
    }

    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }

  .tasks {
    margin-top: 16px;
    width: 100%;
    height: calc(100% - 40px);

    // flex: 1;
    // overflow: scroll;
    .pagination {
      min-width: 450px;
    }

    ::v-deep .el-table {
      min-width: 450px;
    }

    ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
      overflow-x: hidden;
    }
  }

  .creat-dialog {
    font-size: 12px;

    .content {
      margin: 20px;

      .work_state {
        display: flex;
        justify-content: flex-start;
        position: relative;

        .circle {
          .big-circle {
            width: 12px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -6px;
            margin-top: -6px;
          }

          .small-circle {
            width: 8px;
            height: 8px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -4px;
            margin-top: -4px;
          }
        }

        .title {
          margin-left: 10px;
          font-size: 16px;
          color: #313a46;
          line-height: 30px;
          font-weight: 450;
        }
      }

      .work_detail {
        display: flex;
        justify-content: space-around;
        align-items: center;

        p {
          font-size: 12px;
          margin-top: 8px;
          line-height: 16px;

          .just {
            color: #313a46;
            font-weight: 450;
            display: inline-block;
            width: 90px;
            text-align: justify;
            text-align-last: right;
          }

          span {
            color: #737d85;
          }
        }
      }
    }
  }

  .node-detail {
    min-width: 1400px;
    padding: 20px;
    position: relative;

    .back-btn {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 16px;
      color: #165DFF;
      margin-bottom: 30px;

      .el-icon {
        color: #165DFF;
        font-size: 16px;
        margin-right: 8px;
      }
    }

    .detail-body {
      .detail-bottom {
        display: flex;
        gap: 16px;

        .detail-card {
          flex: 1;
          height: 300px;
        }
      }

      .detail-card {
        position: relative;
        height: 144px;
        background: #f5f7fa;
        border-radius: 6px 6px 6px 6px;
        border: 1px solid #CFD3E3;

        .title {
          position: absolute;
          top: -15px;
          left: 16px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #303133;
          line-height: 24px;
          text-align: center;
          height: 30px;
          line-height: 30px;
          background: #FFFFFF;
          padding: 0 34px;
          border: 1px solid #CFD3E3;
        }

        .content {
          padding: 32px 0 20px 34px;
          display: grid;
          height: 100%;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr;

          .content-item {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #909399;

            span {
              &:nth-child(2) {
                display: inline-block;
                color: rgba(0, 0, 0, 0.8);
                margin-left: 8px;
                width: 280px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
              }

              &:nth-child(1) {
                display: inline-block;
                width: 120px;
                text-align: right;
                vertical-align: middle;
              }
            }
          }
        }
      }
    }
  }

  // .node-detail{
  //   padding: 20px;

  //   .node-detail-header{
  //     display: flex;
  //     height: 72px;
  //     // position: sticky;
  //     // top: 0;
  //     // z-index: 99;
  //     // background-color: #f5f5f5;
  //     .back-btn{
  //       width: 72px;
  //       margin-right: 16px;
  //       background-color: #fff;
  //       line-height: 72px;
  //       text-align: center;
  //       display: grid;
  //       place-items: center;
  //       .back-icon{
  //         width: 30px;
  //         height: 30px;
  //       }
  //     }
  //     .title{
  //       border-radius:8px ;
  //       background-color: #fff;
  //       flex: 1;
  //       font-weight: 400;
  //       font-size: 14px;
  //       padding: 0 48px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: space-around;
  //       height: 72px;
  //     }
  //   }
  //   .node-detail-content{
  //     display: flex;
  //     flex-wrap:wrap;
  //     margin-top: 16px;
  //     gap: 16px;
  //     .node-detail-item{
  //       width: 410px;
  //       height: 300px;
  //       border-radius: 8px;
  //       background-color: #fff;
  //       padding:0 16px;
  //       .item-title{
  //         font-weight: 500;
  //         font-size: 14px;
  //         color: #303133;
  //         border-bottom: 1px solid #f2f3f5;
  //         line-height: 56px;
  //         height: 56px;
  //         span{
  //           color: #165DFF;
  //         }
  //         .title-text{
  //           float: right;
  //           color: #909399;
  //           font-size: 12px;
  //         }
  //       }
  //     }
  //   }
  // }
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}

.back-icon {
  font-size: 72px;
}
</style>
