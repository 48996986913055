<template>
  <div ref="chart" style="width: 100%; height: 100%;" />
</template>

<script>
const echarts = require('echarts')
const option = {
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '10%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisTick: {
      show: false
    }
  },
  yAxis: {
    name: '%',
    nameLocation: 'end',
    type: 'value',
    min: 0,
    max: 100,
    splitNumber: 5,
    axisTick: {
      show: false
    }
  },
  legend: {
    bottom: '2%',
    left: 'center',
    icon: 'circle'
  },
  series: [
  ]
}
export default {
  name: 'TaskEchartsLine1',
  props: {
    isShowNodeDetail: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default: () => []
    },
    xData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chart: null

    }
  },
  computed: {
    seriesData() {
      return {
        isShowNodeDetail: this.isShowNodeDetail,
        data: this.data,
        xData: this.xData
      }
    }
  },
  watch: {
    seriesData: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.initChart()
          })
        }
      },
      deep: true
    }
  },
  mounted() {
    const elementResizeDetectorMaker = require('element-resize-detector')// 导入
    // 创建实例
    const erd = elementResizeDetectorMaker()
    const i = document.getElementsByClassName('task').length - 1
    erd.listenTo(document.getElementsByClassName('task')[i], (element) => {
      try {
        setTimeout(() => {
          this.chart?.resize()
        }, 100)
      } catch (error) {
        console.log(error)
      }
    })
  },
  methods: {
    initChart() {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chart)
      }
      option.series = this.data.map(item => {
        return {
          name: item.nodeName,
          type: 'line',
          smooth: true,
          data: item.data,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(100, 255, 236,0.12)'
              },
              {
                offset: 1,
                color: 'rgba(52, 255, 253,0)'
              }
            ])
          },
          itemStyle: {
            color: '#37E2E2'
          },
          symbol: 'none'
        }
      })
      option.xAxis.data = this.xData
      this.chart.setOption(option)
    }
  }
}
</script>

<style>

</style>
